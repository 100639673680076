exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-get-involved-index-jsx": () => import("./../../../src/pages/get-involved/index.jsx" /* webpackChunkName: "component---src-pages-get-involved-index-jsx" */),
  "component---src-pages-get-involved-thanks-jsx": () => import("./../../../src/pages/get-involved/thanks.jsx" /* webpackChunkName: "component---src-pages-get-involved-thanks-jsx" */),
  "component---src-pages-join-the-network-thanks-jsx": () => import("./../../../src/pages/join-the-network/thanks.jsx" /* webpackChunkName: "component---src-pages-join-the-network-thanks-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-open-letter-index-tsx": () => import("./../../../src/pages/open-letter/index.tsx" /* webpackChunkName: "component---src-pages-open-letter-index-tsx" */),
  "component---src-pages-publications-index-jsx": () => import("./../../../src/pages/publications/index.jsx" /* webpackChunkName: "component---src-pages-publications-index-jsx" */),
  "component---src-pages-survey-index-tsx": () => import("./../../../src/pages/survey/index.tsx" /* webpackChunkName: "component---src-pages-survey-index-tsx" */),
  "component---src-pages-tags-index-jsx": () => import("./../../../src/pages/tags/index.jsx" /* webpackChunkName: "component---src-pages-tags-index-jsx" */),
  "component---src-templates-events-article-tsx": () => import("./../../../src/templates/events-article.tsx" /* webpackChunkName: "component---src-templates-events-article-tsx" */),
  "component---src-templates-ignited-page-tsx": () => import("./../../../src/templates/ignited-page.tsx" /* webpackChunkName: "component---src-templates-ignited-page-tsx" */),
  "component---src-templates-ignited-recipient-tsx": () => import("./../../../src/templates/ignited-recipient.tsx" /* webpackChunkName: "component---src-templates-ignited-recipient-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-join-the-network-page-tsx": () => import("./../../../src/templates/join-the-network-page.tsx" /* webpackChunkName: "component---src-templates-join-the-network-page-tsx" */),
  "component---src-templates-national-strategy-page-tsx": () => import("./../../../src/templates/national-strategy-page.tsx" /* webpackChunkName: "component---src-templates-national-strategy-page-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/news-article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-priority-tsx": () => import("./../../../src/templates/priority.tsx" /* webpackChunkName: "component---src-templates-priority-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../../../src/templates/profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */),
  "component---src-templates-rapid-review-index-tsx": () => import("./../../../src/templates/rapid-review-index.tsx" /* webpackChunkName: "component---src-templates-rapid-review-index-tsx" */),
  "component---src-templates-rapid-review-tsx": () => import("./../../../src/templates/rapid-review.tsx" /* webpackChunkName: "component---src-templates-rapid-review-tsx" */),
  "component---src-templates-sitemap-page-tsx": () => import("./../../../src/templates/sitemap-page.tsx" /* webpackChunkName: "component---src-templates-sitemap-page-tsx" */),
  "component---src-templates-stream-tsx": () => import("./../../../src/templates/stream.tsx" /* webpackChunkName: "component---src-templates-stream-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-thinktank-details-tsx": () => import("./../../../src/templates/thinktank-details.tsx" /* webpackChunkName: "component---src-templates-thinktank-details-tsx" */),
  "component---src-templates-thinktank-page-25-tsx": () => import("./../../../src/templates/thinktank-page-25.tsx" /* webpackChunkName: "component---src-templates-thinktank-page-25-tsx" */),
  "component---src-templates-thinktank-page-tsx": () => import("./../../../src/templates/thinktank-page.tsx" /* webpackChunkName: "component---src-templates-thinktank-page-tsx" */),
  "component---src-templates-top-10-page-tsx": () => import("./../../../src/templates/top-10-page.tsx" /* webpackChunkName: "component---src-templates-top-10-page-tsx" */),
  "component---src-templates-translated-tsx": () => import("./../../../src/templates/translated.tsx" /* webpackChunkName: "component---src-templates-translated-tsx" */),
  "component---src-templates-what-we-do-page-tsx": () => import("./../../../src/templates/what-we-do-page.tsx" /* webpackChunkName: "component---src-templates-what-we-do-page-tsx" */),
  "component---src-templates-who-is-involved-page-tsx": () => import("./../../../src/templates/who-is-involved-page.tsx" /* webpackChunkName: "component---src-templates-who-is-involved-page-tsx" */)
}

